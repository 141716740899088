import { get } from "lodash-es";

export const numericAsc = (a, b) => parseInt(a) < parseInt(b) ? -1 : parseInt(a) > parseInt(b) ? 1 : 0;

export const textAsc = !!self.Intl && !!self.Intl.Collator ? new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', caseFirst: 'lower' }).compare : (a, b) => a < b ? -1 : a > b ? 1 : 0;

export const comparer = function(property, by=textAsc) {
    return (a, b) => by(get(a, property, a), get(b, property, b));
}

export function sortBy(list, property, by=textAsc) {
    list.sort(comparer(property, by));
    return list;
}