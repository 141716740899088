
import { updateItems } from '@parkingboss/svelte-utils';
import { toZoneISOString } from "./datetime";
import { api, auth } from "./auth";
import { throttle } from "lodash-es";

export { api };


import t1 from "../../assets/1jnge36s5n3mhb8j16yy8rm87g.json";

const tours = {
    "1jnge36s5n3mhb8j16yy8rm87g":t1
};


// update auth header as auth changes
export let authHeader = "";
auth.subscribe($auth => (authHeader = ($auth && `${$auth.type} ${$auth.token}`)));

export function viewpoint() {
    return toZoneISOString(new Date());
}

export function base() {
    return api.settings.apiBase;
}

export function authorize() {
    return "&Authorization=" + (authHeader || "");
}

export async function fetchAndStoreProperty(property) {

    const res = await fetch(`${base()}/properties?viewpoint=${viewpoint()}&property=${property}`);
    const json = await res.json();

    for(const item of Object.values(json.items)) {
        json.items[item.uuid.split('-').join('')] = item;
    }

    updateItems(json);

    return json;
}

export async function fetchAndStorePolicies(property) {

   const res = await fetch(`${base()}/permits/policies/issue?scope=${property}&viewpoint=${viewpoint()}&public=true&admin=false&disabled=false`);

   const json = await res.json();

    statify(json);

    updateItems(json);

    return json;

}

export async function fetchUnits(scope) {
    if(!scope) return null;

    const res = await fetch(`${base()}/units?scope=${scope}&viewpoint=${viewpoint()}`);
    const json = await res.json();

    return json;

}

function statify(data, state) {

    state = state || data;
    state = state.items || state;

    for(const [ key, value ] of Object.entries(data)) {
        
        if(!value.items) continue;
        
        state[key] = value;
        
        for(const [ id, v ] of Object.entries(value.items)) {
            if(typeof(v) !== "string") continue;
            value.items[id] = state[v] || state[id] || v;
        }
        
    }
}

export async function fetchAndStoreTour(id) {
    
    const json = {
        items: {}
    };

    const tour = tours[id];

    console.log("tour=", tour);

    if(tour) {

        json.items[tour.id] = tour;

        for(let i = 0; i < tour.steps.length; i++) {
            const step = tour.steps[i];

            if(tour.steps[i - 1]) step.previous = tour.steps[i - 1].id;
            if(tour.steps[i + 1]) step.next = tour.steps[i + 1].id;

            json.items[step.id] = step;

        }

    }

    updateItems(json);

    return tour;

}

export async function fetchAndStoreUnits(scope) {
    const json = await fetchUnits(scope);

    statify(json);

    updateItems(json);

    return json;

    //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreTenant(id) {
    if(!id) return null;

    const res = await fetch(`${base()}/units/tenants?tenant=${id}&viewpoint=${viewpoint()}`);
    const json = await res.json();

    // expand subjects
    for(const value of Object.values(json.items)) {
        if(!value.subject) continue;
        value.subject = json.items[value.subject] || value.subject;
    }

    statify({
        tenants:json.tenants
    }, json);

    updateItems(json);

    return json;

    //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreSpaces(scope) {
    
    const json = await fetchSpaceStatus(scope);

    statify(json);

    updateItems(json);

    return json;
}

export const fetchSpaceStatus = throttle(async function fetchSpaceStatus(scope, valid) {
    if(!valid) valid = viewpoint()+"/";

    var url = `${base()}/locations/${scope}/permits/spaces/summary?prices=true&viewpoint=${viewpoint()}&valid=${valid}`;
    //console.log("spaces=", url);

    const res = await fetch(url);
    //console.log("spaces res", res);
    const json = await res.json();

    // copy prices to set by subject
    if(json.prices) {
        for(const [ id, v ] of Object.entries(json.prices.items)) {
            if(typeof(v) !== "string") continue;
            const price = json.prices.items[id] = json.items[v] || json.items[id] || v;

            if(price.subject) {
                json.prices.items[price.subject] = (json.prices.items[price.subject] || []);
                json.prices.items[price.subject].push(price);
            }
        }
    }

    return json;
}, 3 * 1000, {
 leading:true,
 trailing:true,
});

export async function fetchAndStorePrices(scope) {
    const json = await fetchPrices(scope);

    statify(json);

    updateItems(json);


}

export async function fetchPrices(scope) {
    if(!scope) return null;
    const res = await fetch(`${base()}/prices?scope=${scope.id || scope}&viewpoint=${viewpoint()}&valid=${viewpoint(1000 * 60)}/${authorize()}`);
    const json = await res.json();

    return json;
}


export function resolvePermits(values, items) {

    // values is the list of permits, items is the overall state

    if(!values || !items) return null;

    for(let [id, permit ] of Object.entries(values)) {
        if(typeof permit == "string") permit = items[permit] || items[id] || permit;

        if(!permit || !permit.id) continue;
        
        values[id] = Object.assign(permit, {
            property: resolveProperty(items[permit.location] || permit.location, items),
            address: items[permit.address] || permit.address,
            policy: items[permit.issued.policy] || items[permit.issued.issuer] || permit.issued.issuer,
            //vehicle: items[permit.vehicle] || permit.vehicle,
            //media: items[permit.media] || permit.media,
            //spaces: (permit.spaces || []).map(i => items[i] || i),
            subjects: (permit.subjects || []).reduce((result, item) => {
                item = items[item] || item;
                result[item.id || item] = item;
                return result;
            }, {}),
            //tenant: items[permit.tenant] || permit.tenant,
        });

    }

    return values; 

}

export function resolveAddress(item, items) {
    if(!item) return item;
    item.address = items[item.address] || item.address;
    return item;
}

export function resolveProperty(item, items) {
    if(!item) return item;
    if(typeof item === "string") item = items[item];
    if(item) item.stripe = items.stripe;
    return resolveAddress(item, items);
}