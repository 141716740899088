import { api, viewpoint, base } from "../../util/api";

export async function fetchPropertyGeoFeatures(property) {
    const res = await fetch(`${api.settings.apiBase}/geo?viewpoint=${viewpoint()}&scope=${property.id || property}&valid=${viewpoint()}/`)
    const json = await res.json();
    return json;
}


export async function fetchPropertyGeoBase(property, branch) {
    const res = await fetch(`${api.settings.apiBase}/properties/${property.id || property}/map?viewpoint=${viewpoint()}&branch=${branch||""}`)
    //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
    const json = await res.json();
    return json;
}

export async function fetchPropertyGeoFeaturesStatic(scope, branch) {
    let url = `${base()}/properties/${scope}/map?viewpoint=${viewpoint()}`;
    if(!!branch) url += `&branch=${branch}`;
    //console.log("map url=", url);
    const res = await fetch(url);
    //console.log("map res", res);
    const json = await res.json();

    return json;
}

export async function fetchPropertyGeoFeaturesDynamic(property, valid) {
    if(!valid) valid = viewpoint()+"/";
    const url = `${base()}/geo?viewpoint=${viewpoint()}&scope=${property.id || property}&valid=${valid}`;
    //console.log("features url=", url);
    const res = await fetch(url);
    //console.log("features res", res);
    const json = await res.json();
    return json;
}

export async function fetchMapillaryFeatures() {
    const res = await fetch("https://a.mapillary.com/v3/images?client_id=WVBSRlJ6c0pENmNFa3A1b04ya1hRdzo5NTYxYjUxMzkyY2Q3NTA4&bbox=-80.34101330083075,25.81732033520379,-80.33188709614373,25.822063593492487&organization_keys=LgKSejt1ic9xPYwE2um2Cn");
    const json = await res.json();
    return json;
}