
import { get as param } from "../../util/params";
import { propertyId } from "../../util/stores";
import { fetchPropertyGeoFeaturesDynamic, fetchPropertyGeoFeaturesStatic, fetchMapillaryFeatures } from "./api";
import { writable, derived, readable } from "svelte/store";
import { get } from "lodash-es";

export const mapbranch = derived(param("branch", true), $value => $value || "develop");

const geojson = param("geojson");
export const custom = param("geojson", true);

export const position = derived(param("position"), $value => $value && $value.split(",").map(parseFloat));

export const inspect = derived(param("inspect"), $value => $value === "true" || $value === true);

let activeBranch;
export const propertyGeoJsonStatic = derived([propertyId, mapbranch, geojson], async([$propertyId, $branch, $url], set) => {


    if($branch != activeBranch) activeBranch = $branch;

    if (!$propertyId) return set({
        "type": "FeatureCollection",
        "features": [],
    });

    //console.log("fetching geojson=", $propertyId, $branch);

    if($url) {
        return set(JSON.parse(await (fetch($url).then(res => res.text()))));
    }

    await fetchPropertyGeoFeaturesStatic($propertyId, $branch)

    const json = await fetchPropertyGeoFeaturesStatic($propertyId, $branch);
    //console.log("testingset=", $branch, activeBranch);
    if($branch == activeBranch) set(json); // make sure this branch is still active


    //return await fetchAndStorePropertyGeojson($propertyId);
}, {
    "type": "FeatureCollection",
    "features": [],
});

export const propertyGeoJsonDynamic = derived([propertyId], async([$propertyId], set) => {
    if (!$propertyId) return set({
        "type": "FeatureCollection",
        "features": [],
    });

    //console.log("fetching geojson=", $propertyId);

    set(await fetchPropertyGeoFeaturesDynamic($propertyId));


    //return await fetchAndStorePropertyGeojson($propertyId);
}, {
    "type": "FeatureCollection",
    "features": [],
});

export const level = derived(param("level"), $level => {
    if(null == $level) return null;
    if($level === "") return null;
    return $level;
});

//level.subscribe($value => console.log("level=", $value));