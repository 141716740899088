import { writable, derived, readable } from "svelte/store";
import { items } from "@parkingboss/svelte-utils";
import { params, get as param } from "./params";
import { fetchAndStoreProperty, fetchAndStorePolicies, fetchAndStoreUnits, fetchAndStoreSpaces, resolveProperty, fetchAndStoreTour } from "./api";
import { sortBy } from "./sort";

export { items as state }

export const tourId = param("tour", true);

export const stepId = param("step");


export const tour = derived([ tourId, items ], ([ $tourId, $state ]) => {

    return $state[$tourId];

});

export const propertyId = derived(tour, $tour => $tour && $tour.property);

// set property
export const property = derived([propertyId, items], ([$id, $state]) => resolveProperty($id, $state));

export const units = derived([ propertyId, items ], ([ $propertyId, $state ]) => {
    if(!$propertyId) return null;
    if(!$state.units || !$state.units.items) return null;
    return sortBy(Object.values($state.units.items), "display")
});

export const spaces = derived([ propertyId, items ], ([ $propertyId, $state ]) => {
    if(!$propertyId) return null;
    if(!$state.spaces || !$state.spaces.items) return null;
    return sortBy(Object.values($state.spaces.items), "display");
});

export const steps = derived(tour, $tour => $tour && $tour.steps);

export const step = derived([ stepId, items ], ([ $stepId, $state ]) => {
    console.log("step=", $stepId, $state[$stepId], $state)
    return $stepId && $state[$stepId];
});

export const selectedId = derived(step, $step => $step && $step.place);

export const selected = derived([ selectedId, items ], ([ $selected, state ]) => {
    return $selected && state[$selected];
});

export const level = derived(step, $step => $step && (null != $step.level ? $step.level : $step && $step.feature && $step.feature.properties.level));

tourId.subscribe($tourId => {
    if(!$tourId) return;
    fetchAndStoreTour($tourId);
});

propertyId.subscribe($propertyId => {
    if(!$propertyId) return;
    fetchAndStoreProperty($propertyId);
    fetchAndStorePolicies($propertyId);
    fetchAndStoreUnits($propertyId);
    fetchAndStoreSpaces($propertyId);
    //fetchAndStoreTour($propertyId);
});