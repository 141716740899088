<script>

    export let step;



</script>
{#if step}
    {#if step.image}
    <figure><img src="{step.image}"></figure>
    {/if}
    {#if step.description}
    <p class="description">{step.description}</p>
    {/if}
{/if}