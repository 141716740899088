<script>
    import Map from "./components/maps/Map.svelte";
    import MapMarker from "./components/maps/MapMarker.svelte";
    import MapFeature from "./components/maps/MapFeature.svelte";
    import MapInspector from "./components/maps/MapInspector.svelte";
    import Tour from "./components/Tour.svelte";
    import Record from "./components/Record.svelte";
    import StepFeature from "./components/StepFeature.svelte";
    import StepInfo from "./components/StepInfo.svelte";
    import MapPositionMarker from "./components/maps/MapPositionMarker.svelte";
    import MapPosition from "./components/maps/MapPositionControl.svelte";

    import { propertyGeoJsonStatic, propertyGeoJsonDynamic, inspect, position } from "./components/maps/stores";
    import { scaleBbox, bbox } from "./util/geo";
    import { property, state, selectedId, selected, level, tour, step, steps } from "./util/stores";
    import { smscreen } from "./util/envstores";
    import { processFeatures, filterLevel, processUnitRecords, processSpaceRecords, processSpacePolicies, processSelected, levelRefs, nameLevels, processParkingEntrances, processAddrPost } from "./util/geopipeline";
    import { query } from "./util/router";
    import style from "../assets/community.json";
    import { tick } from 'svelte';
    import { sortBy, numericAsc } from "./util/sort";

    let mapbox;

    let attribution;

    let sources = {};
    let levels = {};
    let points = [];
    let unitPoints = {};
    let activeBbox;
    let levelPoints = [];
    let selectedLevel;

    //$: $level && console.log("level = ", $level);
    //$: $selectedId && console.log("place = ", $selectedId);

    //$: console.log("$tenants=", $tenants);

    // run pipeline on any change
    $: pipeline(
        [ 
            $propertyGeoJsonStatic, 
            $propertyGeoJsonDynamic 
        ], 
        $level, 
        $state, 
        [ $selectedId ], 
        ([ $selected ] || []).reduce((result, item) => {
            if(!item) return result;
            //console.log("process subject item=", item);
            if(item.subject) result[item.subject.id || item.subject] = item.subject;
            else if(item.id) result[item.id] = item;
            if(item.subjects) {
                for(const id in item.subjects) {
                    result[id] = item.subjects[id];
                }
            }
            return result;
        }, {}),
        null,
        $steps || []
    );

    function pipeline(geojson, level, state, selected, subjectsMap, account, steps) {

        if(!geojson) return;

        const spaces = [];
        const units = [];
        let features = [];
        //const newLevels = {};

        attribution = geojson.reduce((result, geojson) => (geojson.properties && geojson.properties.branch ? `${geojson.properties.branch} ${geojson.properties.commit} ${geojson.properties.build}` : result), null);

        const allFeatures = geojson.flatMap(geojson => (geojson && geojson.features) || geojson || []);

        //console.log("bboxing all features=", allFeatures);

        if(!activeBbox) {
            activeBbox = allFeatures.length && bbox({
                "type":"FeatureCollection",
                "features":allFeatures
            });
            //console.log("init bbox=", activeBbox);
        } else if(allFeatures.length) {
            var newbbox = bbox({
                "type":"FeatureCollection",
                "features":allFeatures
            });
            // contained?
            if(newbbox.filter((point, i) => i < 2 ? point < activeBbox[i] : point > activeBbox[i]).length) {
                activeBbox = newbbox.map((point, i) => Math[ i < 2 ? "min" : "max"](point, activeBbox[i]));
                //console.log("update bbox=", activeBbox);
            }
        }


        processFeatures(allFeatures, features, levels, spaces, units);

        nameLevels(levels);

        processSpaceRecords(spaces, state && state.spaces && state.spaces.items);
        processUnitRecords(units, state && state.units && state.units.items);

        //processSpacePolicies(spaces, state);

        if(account) processAddrPost(features, account && account.subject);

        points = [];

        //console.log("subjectsmap = ", subjectsMap);

        for(const item of steps) {
            if(state && state.policies && state.policies.items && state.policies.items[item.place]) {
                // reffing a policy
                const source = state.policies.items[item.place];
                if(!item.name) item.name = source.title;
                if(!item.image) item.image = source.photo && (source.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/") + "?auto=compress,format&w=500");
            }
        }

        for(const feature of features) {

            if (account && feature.properties["ref:boss:subject"] && subjectsMap[feature.properties["ref:boss:subject"]]) {

                feature.properties["access"] = "yes";

            }

            // only take points for directory

            // for(const item of steps) {

            //     if(feature.id == item.place) item.feature = feature;
            //     if(feature.properties["ref:boss:subject"] == item.place) item.feature = feature;

            // }

            if(feature.geometry.type != "Point") continue; // only points

            
            
            // if(feature.properties.office) markerFeatures.push(feature);
            // if(feature.properties.amenity == "parcel") markerFeatures.push(feature);
            // if(feature.properties.amenity == "post_office") markerFeatures.push(feature);
            if(feature.id && feature.properties.name) points.push(feature);
            else if(feature.id && feature.properties.office) points.push(feature);
            //else if(feature.id && feature.properties.amenity == "parcel_lockers") points.push(feature);
            //else if(feature.id && feature.properties.amenity == "post_office") points.push(feature);
            else if(state && state.policies && state.policies.items && state.policies.items[feature.properties["ref:boss:subject"]]) {
                const item = state.policies.items[feature.properties["ref:boss:subject"]];
                if(!feature.properties.name) feature.properties.name = item.title;
                if(!feature.properties.image) feature.properties.image = item.photo && (item.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/") + "?auto=compress,format&w=500");
                points.push(feature);
                
            } else if (feature.properties["ref:boss:subject"] && subjectsMap[feature.properties["ref:boss:subject"]]) {

                // interested
                points.push(feature);

            }


            for(const item of steps) {

                if(feature.id == item.place) item.feature = feature;
                if(feature.properties["ref:boss:subject"] == item.place) item.feature = feature;
                if(item.feature) {
                    for(const k of Object.keys(item.feature.properties || {})) {
                        if(!item[k]) item[k] = item.feature.properties[k];
                    }
                }

            }

        }

        levelRefs(points, levels);

        points = points.filter(feature => feature.properties.access != "no"); // filter out things where no access

        processSelected(features, selected);

        processParkingEntrances(features);

        // if(null == level) {
        //     var autolevel = features.reduce((level, feature) => {
        //         if(feature.properties.selected != "yes") return level;
        //         if(null == feature.properties.level) return level;
        //         const fl = feature.properties.level.split ? Math.min(feature.properties.level.split(';').map(i => parseInt(i))) : feature.properties.level;
        //         if(null == level) return fl;
        //         return Math.min(fl, level); 
        //     }, null);
        //     if(null != autolevel) level = autolevel;
        // }

        selectedLevel = level;

        if(unitPoints) unitPoints = units.reduce((result, feature) => {
            if(feature.geometry.type != "Point") return result;
            if(!feature.properties["ref:boss:subject"]) return result;
            result[feature.properties["ref:boss:subject"]] = feature;
            return result;
        }, {});//sortBy(units.filter(f => f.geometry.type == "Point"), "properties.name");

        

        features = filterLevel(features, level).features;

        // update sources
        sources = Object.assign(sources, {
            property: {
                "type":"geojson",
                "generateId":true,
                "data":{
                    "type":"FeatureCollection",
                    "features":features
                },
                "attribution":attribution
            }
        });

        //console.log("newlevels=", levels);

        //levelName = newLevels[level];

        //levels = Object.entries(newLevels);
        levels = levels;

        levelPoints = filterLevel(points, level).features

    }

    //$: console.log("directory=", points);
    //$: levelPoints = filterLevel(points, $level).features;
    // $: pointsByLevel = points.reduce((result, item) => {

    //     const group = result["level:" + item.properties.level] || (result["level:" + item.properties.level] = {
    //         level: item.properties.level,
    //         name: floorName(item.properties.level),
    //         points:[],
    //     });

    //     group.points.push(item);

    //     return result;

    // }, {});
    $: selectedPoints = levelPoints.filter(feature => "yes" == feature.properties.selected);

    $: if(mapbox && selectedPoints.length == 1) {

        // center
        mapbox.flyTo({
            center: selectedPoints[0].geometry.coordinates,
            duration:100,
            zoom:19
        });
    } else if (mapbox && activeBbox) {

        mapbox.fitBounds(activeBbox, {
            linear:true,
            duration:100,
        });

        // mapbox.flyTo({
        //     duration:100,
        //     zoom:17
        // });
    }

    $: if(selectedPoints) window.scrollTo(0, 0);
    // } else if(mapbox && selectedLevel != "fake") {
    //     mapbox.flyTo({
    //         duration:100,
    //         zoom:17
    //     });
    // }

    $: if(mapbox && (null != $smscreen)) mapbox.setPadding({
        left: $smscreen ? 0 : 0,
        bottom: $smscreen ? 300 : 0,
    });

    $: if(mapbox && mapbox.getLayer("building label")) mapbox.setLayoutProperty("building label", "visibility", null != $level ? "none" : "visible");

    //$: console.log("points by level=", pointsByLevel);

    async function url(feature) {
        await tick();
        //console.log("select=", feature);
        if(!feature) return query({
            place: null,
            level: null,
        });

        query({
            place: feature.properties["ref:boss:subject"] || feature.id,
            level: feature.properties.level && (feature.properties.level+"").split(";")[0], // go the the first floor it's on if multiple
        });
    }

    function floorName(level=null) {
        if(null == level || "" == level) return "Outside";
        return  levels[level] || ("Floor " + level)
    }

    function unitSelect(select) {
        if(select.value) url(unitPoints[select.value]);
        select.value = "";
    }

</script>
<header>
    <Tour tour={$tour} />
    {#if $step}
    <button type="button" class="itinerary" on:click={e => query("step", null)}>Itinerary</button>
    {/if}
</header>
<Map bind:mapbox={mapbox} style={style} sources={sources} maxBbox={activeBbox && scaleBbox(activeBbox, 4)} logoPosition={$smscreen ? "top-left" : "bottom-left"}>
    {#if $position}
        <MapPositionMarker position={$position} />
    {/if}
    {#each selectedPoints as feature}
        <MapMarker feature={feature} selected={feature.properties.selected == "yes"} />
    {/each}
    {#if $inspect}
    <MapInspector />
    {/if}
</Map>
<main>
    {#if $step}
        <header>
            <!-- <button class="close" type="button" on:click={e => query({
                place:null,
                level:null,
            })}>Close</button> -->

            <h1>
                {#if $step.name}
                {$step.name}
                {:else if $state[$selectedId]}
                <Record record={$state[$selectedId]} />
                {:else if selectedPoints[0] }
                {selectedPoints[0].properties.name}
                {/if}
            </h1>
            {#if selectedPoints[0]}
            <address>
                <data class="street">{[ selectedPoints[0].properties["addr:housenumber"], selectedPoints[0].properties["addr:street"] ].filter(i => !!i).join(" ")}</data>
                <data class="floor" value="{selectedPoints[0].properties.level}">{selectedPoints[0].properties["level:ref"]}</data>
            </address>
            {/if}
            
        </header>
        <nav class="next previous">
            <button class="previous" on:click={e => query("step", $step.previous)}></button>
            {#if $step.next}
            <button class="next" on:click={e => query("step", $step.next)}>Next</button>
            {/if}
        </nav>
        <!-- <section class="selected"> -->
            <!-- <MapFeature feature={selectedPoints[0]} /> -->
            <!-- <Policy policy={$state[$selectedId]} /> -->
            <!-- <FeatureInfo feature={selectedPoints[0]} /> -->
            <StepInfo step={$step} />

    {:else}
        <header>
            <h1>{$property?.name || "Loading…"}</h1>
            {#if $property?.address}
            <address>
                <span class="street">{$property.address.street}</span>
                <span class="city">{$property.address.city}</span>
                <span class="state">{$property.address.state}</span>
                <span class="postal">{$property.address.postal}</span>
            </address>
            {/if}
            
        </header>
        
        {#if $property?.photo}
            <figure><img src="{$property.photo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/") + "?auto=compress,format&w=500"}"></figure>
        {/if}
  
        <!-- {#if activeBbox}
        <MapPosition autostart={false} bbox={activeBbox} on:geolocation={e => e.detail && query("position", (e.detail && e.detail.position && e.detail.position.coords && `${e.detail.position.coords.longitude},${e.detail.position.coords.latitude}`))} />
        {/if} -->
        <nav class="itinerary">
            {#if $steps}
            <ol>
                {#each $steps as item}
                <li>
                    <h1>{item.name}</h1>
                    <address>
                    {#if item["level:ref"]}<data class="floor" value="{item.level}">{item["level:ref"]}</data>{/if}
                    </address>
                    <StepFeature step={item} />
                    {#if item.image}
                    <figure><img src="{item.image}"></figure>
                    {/if}
                    {#if item.description}
                    <p class="description">{item.description}</p>
                    {/if}
                    <button type="button" on:click={e => query("step", item.id)}>View On Map</button>
                </li>
                {/each}
            </ol>
            {/if}
        </nav>
        <footer>
            {#if $property && $property.logo}
            <figure class="logo">
                <img class="logo" alt="{$property.name} logo" src="{$property.logo.url.replace("https://upload.parkingboss.com/files/", "https://amenitypass.imgix.net/")}?auto=compress,format"/>
            </figure>
            {/if}
        </footer>
    {/if}
</main>