<script>
    import { createEventDispatcher } from "svelte"; 

    export let record;

    const dispatch = createEventDispatcher();

</script>
{#if record && record["addr:unit"]}
    <data on:click={e => dispatch("click", record)} value="{record.id}" class="{ [ "id", record.type, record.format, record.size ].filter(i => !!i).join(" ")}">{record["addr:unit"] || record.display || record.title || record.name}</data>
{:else if record && record.display}
    <data on:click={e => dispatch("click", record)} value="{record.id}" class="{ [ "id", record.type, record.format, record.size ].filter(i => !!i).join(" ")}">{record.display || record.title || record.name}</data>
{:else if record}
    <data on:click={e => dispatch("click", record)} value="{record.id}" class="{ [ record.type, record.format, record.size ].filter(i => !!i).join(" ")}">{record.title || record.name}</data>
{/if}