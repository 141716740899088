import App from "./App.svelte";
import style from "../styles/app.scss";
import { TrackJS } from "trackjs";
import { onRoute } from "./util/session";
import { end, router } from "./util/router";

TrackJS.install({
  token: "f6af63c239f94990b98eb97849dce2e5",
  application: "tour"
});

const app = new App({
  target: document.body
});

export default app;

//router("/", "/?property=g5q8k0rft10kd4xj8gfpd25czg");

router("*", onRoute, end);

router.start();