<script>

    import { onMount, onDestroy, getContext, createEventDispatcher } from "svelte";
	import { mapbox, key } from "./mapbox.js";

	const dispatch = createEventDispatcher();

	const { getMap } = getContext(key);
    const map = getMap();

	export let heading;
	export let position;
	export let feature;
	//let type = feature.properties.amenity || feature.properties.leisure || feature.properties.office || (feature.properties["addr:unit"] && "home");
	export let selected = false;

	$: hasBearing = typeof(heading) === "number";

	let point;

	$: point = (feature && feature.geometry.coordinates) || position;
	$: heading = heading || feature.properties.heading;

	//$: if(feature && feature.properties.amenity) el.setAttribute("data-amenity", feature.properties.amenity)

	var wrapper = document.createElement("h1");
	wrapper.className = "map-marker";

	var el = document.createElement("button");
	el.setAttribute("type", "button");
	wrapper.appendChild(el);

	const marker = new mapbox.Marker(wrapper);

	$: updateElement(el, feature);

	var el2 = document.createElement("div");
	el2.className = "map-marker view-heading";
	const direction = new mapbox.Marker({
		element:el2,
		rotationAlignment:"map",
		pitchAlignment:"map",
		rotation:heading,
	});

	//el.addEventListener("click", e => (selected = !selected));
	// capture runs first
	// map.getContainer().addEventListener("click", e => {
	// 	//console.log("click=", e, el);
	// 	if(!e.target || !el.contains(e.target)) return selected = false;
	// }, true);

	map.getContainer().addEventListener("click", e => {
		//console.log("click=", e, el);
		if(e.target && el.contains(e.target)) dispatch("selected", feature || position); //return selected = true;
	}, false);

	$: if(!feature) selected = false;

	$: wrapper.classList.toggle("selected", selected);

	$: if(selected) {
		//dispatch("selected", feature || position)
		if(hasBearing) {
			//console.log("selected photo heading=", heading);
			direction.setLngLat(point).setRotation(heading).addTo(map);
			// map.flyTo({
			// 	bearing: heading,
			// 	zoom:19,
			// 	center:point
			// });
		}
	}
	$: if(!selected) {
		direction.remove();
		//dispatch("deselected", feature || position);
	}
	
    

	$: if(point) marker.setLngLat(point).addTo(map);

	onDestroy(function() {
		marker.remove(); // remove from map
		direction.remove();
	});

	function updateElement(el, feature) {

		//if(!feature) {
		//console.log("marker update", el.dataset);
		const remove = [];
		for(let i = 0; i < el.attributes.length; i++) if(el.attributes[i].name.startsWith("data")) remove.push(el.attributes[i].name);
		for(const name of remove) el.removeAttribute(name);
		el.innerHTML = `${(feature && feature.properties.name || feature.properties.ref) || ""}`;
		//} 
		if(!!feature) {
			el.setAttribute("data-type", feature.type);
			for(let [ key, value ] of Object.entries(feature.properties)) {
				if(key == "image") continue;
				if(key == "url") continue;
			
				el.setAttribute("data-"+key.replace(/[^0-9a-z]/ig,"-"), value);
			}
			
		}


	}

</script>