<script>
	import { onMount, onDestroy, getContext } from "svelte";
	import { mapbox, key } from "./mapbox.js";

	const { getMap } = getContext(key);
    const map = getMap();

	export let position;
	export let heading = 0;

	// let pitch = 0;

	// map.on("pitchend", updatePitch);
    // map.on("pitch", updatePitch);
    // map.on("pitchstart", updatePitch);

    var el = document.createElement("div");
	el.className = "mapboxgl-user-location-dot";

	// function updatePitch() {
    //     requestAnimationFrame(() => {
	// 		pitch = map.getPitch();
	// 		// el.style.transformOrigin = "center";
	// 		// el.style.transform = `rotateX(${pitch}deg);`;
	// 	});
    // }

		const marker = new mapbox.Marker(el)
		.setPitchAlignment("map")
		.setLngLat(position)
		.addTo(map);

		onDestroy(function() {
			marker.remove();
		});

</script>