<script>
export let step;
let element;

function updateElement(el, src) {

    if(!el) return;

    //if(!feature) {
    //console.log("marker update", el.dataset);
    const remove = [];
    for(let i = 0; i < el.attributes.length; i++) if(el.attributes[i].name.startsWith("data")) remove.push(el.attributes[i].name);
    for(const name of remove) el.removeAttribute(name);
    //el.innerHTML = `$`;
    //} 
    if(!!src) {
        el.setAttribute("data-type", "Feature");
        for(let [ key, value ] of Object.entries(src)) {
            if(key == "image") continue;
            if(key == "url") continue;
        
            el.setAttribute("data-"+key.replace(/[^0-9a-z]/ig,"-"), value);
        }
        
    }


}

$: updateElement(element, step);
</script>
{#if step}
<data bind:this={element}></data>
{/if}